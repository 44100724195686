import { isEmpty } from "lodash";

export const updateObjectValue = (obj1, obj2) => {
  var destination = Object.assign({}, obj1);
  Object.keys(obj2).forEach((k) => {
    if (k in destination) {
      if (obj2[k]) destination[k] = obj2[k];
    }
  });
  return destination;
};
export const checkEmptyDetailsFromArray = (arr = [], key = "detail") => {
  if (arr.length > 0 && arr[0].hasOwnProperty(key) && !isEmpty(arr[0][key])) {
    return false;
  }

  return true;
};

export const hasNonNullValue = (arr = []) => {
  if (arr.length > 0) {
    for (const key in arr[0]) {
      if (arr[0][key] !== null) {
        return true; // Found a non-null value
      }
    }
  }
  return false;
};
