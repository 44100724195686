import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FeAutocomplete } from "../../../FormElements";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import doctorAPI from "../../../../utils/doctorAPI";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import { updateObjectValue } from "../../../../utils/helper";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const surgeryModalityOptions = [
  "Lumpectomy with ALND",
  "Lumpectomy with SnLNBx",
  "Mastectomy with ALND",
  "Mastectomy with SnLNBx",
  "Unplanned Limpectomy",
];
const HispathoStagingClinicalForm = ({
  onStateChange,
  editedObj,
  updatedObj,
}) => {
  const [obj, setObj] = useState({
    histopathologies: [
      {
        detail: "",
        type: "",
        site: "",
        date: null,
      },
    ],
  });

  const [modList, setModList] = useState(surgeryModalityOptions);
  const [hisValue, setHisValue] = useState([]);
  const [clinicalList, setClinicalList] = useState([]);
  const [clinicalValue, setClinicalValue] = useState([]);

  useEffect(() => {
    getModList();
    // getStagingClinical();
    return () => {};
  }, []);

  const getModList = async () => {
    const { data } = await doctorAPI.get("doctor/histopathology-records-all");
    setModList(data.data);
  };
  const getStagingClinical = async () => {
    const { data } = await doctorAPI.get("doctor/staging-clinical-records-all");
    console.log("DDDD", data);
    setClinicalList(data.data);
  };

  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };
  const addDiagnosisField = () => {
    setObj({
      ...obj,
      histopathologies: [
        ...obj.histopathologies,
        {
          date: null,
          detail: "",
          type: "",
          site: "",
        },
      ],
    });
  };
  const removeDiagnosisFields = (index) => {
    const rows = [...obj.histopathologies];
    rows.splice(index, 1);
    setObj({ ...obj, histopathologies: rows });
  };
  const handleChangeDiagnosis = (index, name, value) => {
    const list = [...obj.histopathologies];
    list[index][name] = value;
    setObj({ ...obj, histopathologies: list });
  };
  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);

  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObjectValue(obj, updatedObj);
    }
    if (editedObj) {
      e = updateObjectValue(obj, editedObj);
    }

    if (u) {
      setTimeout(() => {
        setObj(u);
      }, 100);
      return;
    } else if (!u && e) {
      if (e.histopathologies.length === 0) {
        setObj({
          histopathologies: [
            {
              detail: "",
              type: "",
              site: "",
              date: null,
            },
          ],
        });
        return;
      }
      setTimeout(() => {
        setObj(e);
      }, 100);
    }
  }, [updatedObj, editedObj]);
  const checkKey = (arr, keyName) => {
    let keyExist = false;
    arr.map((key) => {
      if (key.hasOwnProperty(keyName)) {
        keyExist = true;
      }
    });
    return keyExist;
  };
  return (
    <>
      {" "}
      <Grid item xs={12} sm={12}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={1}>
            <Grid mb={1} item sm={4}>
              Hispathology
            </Grid>
          </Grid>
          {obj.histopathologies.map((data, index) => {
            const { date, detail, type, site } = data;

            return (
              <Box
                gap={"10px"}
                display={"flex"}
                container
                spacing={2}
                key={index}
                sx={{
                  "> * ": {
                    width: " 100%",
                  },
                }}
              >
                <Box mb={1}>
                  <FeDesktopDatePicker
                    label="Date"
                    fullWidth
                    inputFormat="DD/MM/YYYY"
                    name="date"
                    value={date}
                    //  disabled={editedObj}
                    onChange={(val) => {
                      handleChangeDiagnosis(
                        index,
                        "date",
                        dayjs(val).format("YYYY-MM-DD")
                      );
                    }}
                  />
                </Box>
                <Box mb={1}>
                  <FeAutocomplete
                    options={
                      modList.filter((o) => o.type === "type").length > 0
                        ? modList
                            .filter((o) => o.type === "type")
                            .map((o) => o.name)
                        : []
                    }
                    id="Type"
                    label="Type"
                    value={type}
                    fullWidth
                    //  disabled={editedObj}
                    onChange={(e, v) => {
                      handleChangeDiagnosis(index, "type", v);
                    }}
                  />
                </Box>
                <Box mb={1}>
                  <FeAutocomplete
                    options={
                      modList.filter((o) => o.type === "site").length > 0
                        ? modList
                            .filter((o) => o.type === "site")
                            .map((o) => o.name)
                        : []
                    }
                    id="Site"
                    fullWidth
                    label="Site"
                    value={site}
                    //  disabled={editedObj}
                    onChange={(e, v) => {
                      handleChangeDiagnosis(index, "site", v);
                    }}
                  />
                </Box>
                <Box mb={1}>
                  <FeAutocomplete
                    options={
                      modList.filter((o) => o.type === "detail").length > 0
                        ? modList
                            .filter((o) => o.type === "detail")
                            .map((o) => o.name)
                        : []
                    }
                    id="Detail"
                    label="Detail"
                    fullWidth
                    value={detail}
                    //  disabled={editedObj}
                    onChange={(e, v) => {
                      handleChangeDiagnosis(index, "detail", v);
                    }}
                  />
                </Box>
                {obj.histopathologies.length !== 1 && (
                  <Box>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      color="error"
                      fullWidth
                      //  disabled={editedObj}
                      onClick={() => removeDiagnosisFields(index)}
                    >
                      <DeleteForeverIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            );
          })}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="success"
              //  disabled={editedObj}
              onClick={addDiagnosisField}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default HispathoStagingClinicalForm;
