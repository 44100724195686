import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import { isEmpty } from "lodash";

const PreviewPage = ({ obj = {}, docValue = null }) => {
  const renderFollowedBy = (arr) => {
    if (arr && arr.length > 0) {
      let followed_byArr = [];
      if (arr.length > 0) {
        arr.forEach((element) => {
          if (element.followed_by.length > 0) {
            let followObj = {
              selected: !isEmpty(element)
                ? element.followed_by.map((o) => o.value)
                : [],
              cycle_no: parseInt(
                !isEmpty(element)
                  ? element.followed_by[element.followed_by.length - 1].cycle_no
                  : null
              ),
            };
            followed_byArr.push(followObj);
          }
        });
      }
      return (
        <>
          {followed_byArr.map((entry, index) => {
            const { selected, cycle_no } = entry;

            return (
              <span key={index}>
                {""} Followed by {selected.join(", ")} {cycle_no} Cycle
              </span>
            );
          })}
        </>
      );
    } else return <></>;
  };

  const renderProtocols = (row) => {
    console.log("CHECMO", row);
    if (!isEmpty(row)) {
      const primary = row?.primary;
      const followed = row?.followed_by;

      let arr = [];
      let chemotherapy_protocols = [];
      if (!isEmpty(primary))
        if (primary.selected?.length > 0) {
          chemotherapy_protocols = primary.selected.map((o) => {
            return { value: o, cycle_no: primary.cycle_no };
          });
        }

      arr.push({ chemotherapy_protocols });
      if (!isEmpty(followed))
        if (followed.length > 0) {
          const transformedData = followed.map((item) => {
            const followed_by = item.selected.map((selectedItem) => {
              const cycle_no = item.cycle_no === null ? null : item.cycle_no;
              return {
                value: selectedItem,
                cycle_no: cycle_no,
              };
            });
            arr.push({ followed_by });
            return { followed_by };
          });
        }
      return (
        <h4>
          {" "}
          {arr
            .filter((o) => o.chemotherapy_protocols)[0]
            .chemotherapy_protocols.map((item, i) => item.value)
            .join(" + ")}{" "}
          {
            arr.filter((o) => o.chemotherapy_protocols)[0]
              .chemotherapy_protocols[
              arr.filter((o) => o.chemotherapy_protocols)[0]
                .chemotherapy_protocols.length - 1
            ].cycle_no
          }{" "}
          cycle{" "}
          <span>
            {arr.filter((o) => o.followed_by).length > 0 &&
              arr.filter((o) => o.followed_by)[0].followed_by.length > 0 &&
              arr.filter((o) => o.followed_by)[0].followed_by[0].value !==
                "" && <>{renderFollowedBy(arr.filter((o) => o.followed_by))}</>}
          </span>
        </h4>
      );
    }
  };
  return (
    <Box>
      <Box sx={{ border: "1px solid #f1f1f1" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box>
              Patient name:{" "}
              <span style={{ fontWeight: "bold" }}>{obj?.name}</span>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Registration No.{" "}
              {obj.registration_no && (
                <span style={{ fontWeight: "bold" }}>
                  {obj?.registration_no}
                </span>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Phone: <span style={{ fontWeight: "bold" }}>{obj?.phone}</span>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              Consultant:{" "}
              <span style={{ fontWeight: "bold" }}>
                {docValue ? docValue.name : obj?.doctor_patient?.doctor?.name}
              </span>{" "}
            </Box>
          </Grid>
        </Grid>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12} md={12}>
            <h3>Diagnosis:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Diagnosis Disease Group</TableCell>
                    <TableCell>Diagnosis Subgroup</TableCell>
                    <TableCell>Primary site</TableCell>
                    <TableCell>Metastatic Site</TableCell>
                    <TableCell>Laterality</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{obj.diagnosis_disease_group}</TableCell>
                    <TableCell>{obj.diagnosis_subgroup}</TableCell>
                    <TableCell>{obj.diagnosis_primary_site}</TableCell>
                    <TableCell>
                      {obj.diagnosis_metastatic_sites?.length > 0 &&
                        obj.diagnosis_metastatic_sites.map((item, i) => (
                          <p
                            style={{
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                              paddingBottom: "10px",
                            }}
                            key={i}
                          >
                            {" "}
                            {item.value}
                          </p>
                        ))}
                    </TableCell>

                    <TableCell>{obj.laterality}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={7}>
            <h3>Histopathology:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Histopathology Report</TableCell>
                    <TableCell>Grade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {obj.histopathologies?.length > 0 &&
                        obj.histopathologies.map((item, i) => (
                          <p
                            style={{
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                              paddingBottom: "10px",
                            }}
                            key={i}
                          >
                            {" "}
                            {item.detail}
                          </p>
                        ))}
                    </TableCell>

                    <TableCell>{obj.grade}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={5}>
            <h3>Clock Position/mammogram Based :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Side</TableCell>
                    <TableCell>Position</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.clock_positions?.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>{row.side}</TableCell>
                        <TableCell>{row.position}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <h3>Hormone receptor Status :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ER</TableCell>
                    <TableCell>PR</TableCell>
                    <TableCell>HER2</TableCell>
                    <TableCell>Ki67</TableCell>
                    <TableCell>Diagnosis Group</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.diagnosis_groups?.map((row, index) => (
                    <TableRow>
                      <TableCell>{row.er}</TableCell>
                      <TableCell>{row.pr}</TableCell>
                      <TableCell>{row.her2}</TableCell>
                      <TableCell>{row.ki67}</TableCell>
                      <TableCell>{row.result}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <h3>IHC :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ER</TableCell>
                    <TableCell>PR</TableCell>
                    <TableCell>HER2</TableCell>
                    <TableCell>Fish</TableCell>
                    <TableCell>Synaptophysin</TableCell>
                    <TableCell>Chromogranin</TableCell>
                    <TableCell>gata</TableCell>
                    <TableCell>Others</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.ihcs?.map((row, index) => (
                    <TableRow>
                      <TableCell>{row.er}</TableCell>
                      <TableCell>{row.pr}</TableCell>
                      <TableCell>{row.her2}</TableCell>
                      <TableCell>{row.fish}</TableCell>
                      <TableCell>{row.synaptophysin}</TableCell>
                      <TableCell>{row.chromogranin}</TableCell>
                      <TableCell>{row.gata}</TableCell>
                      <TableCell>{row.others}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12}>
              <h3>TNM Staging (Clinical) :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>T</TableCell>
                      <TableCell>N</TableCell>
                      <TableCell>M</TableCell>
                      <TableCell>Stage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.staging_clinicals?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.t}</TableCell>
                        <TableCell>{row.n}</TableCell>
                        <TableCell>{row.m}</TableCell>
                        <TableCell>{row.result}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <h3>TNM Staging (Pathological) :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>T</TableCell>
                      <TableCell>N</TableCell>
                      <TableCell>M</TableCell>
                      <TableCell>Stage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.staging_pathologicals?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.t}</TableCell>
                        <TableCell>{row.n}</TableCell>
                        <TableCell>{row.m}</TableCell>
                        <TableCell>{row.result}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <h3>Pathological Staging - Details :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>lvsi</TableCell>
                      <TableCell>psi</TableCell>
                      <TableCell>margin</TableCell>
                      <TableCell>ki67</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.staging_pathological_details?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.lvsi}</TableCell>
                        <TableCell>{row.psi}</TableCell>
                        <TableCell>{row.margin}</TableCell>
                        <TableCell>{row.ki67}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12}>
              <h3>Molecular Pathology :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Detail</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.molecular_pathologies?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {" "}
                          {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{row.detail}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <h3>Cancer Marker :</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Unit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {obj?.cancer_markers?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {" "}
                          {row.date && dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.value}</TableCell>
                        <TableCell>{row.unit}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <h3>Chemotherapy Details :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Starting Date</TableCell>
                    <TableCell>Modality</TableCell>
                    <TableCell>LOT</TableCell>
                    {/* <TableCell>Cycle No.</TableCell> */}
                    <TableCell>Protocol</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {obj.chemo_starting_date &&
                        dayjs(obj.chemo_starting_date).format("DD-MM-YYYY")}
                    </TableCell>

                    <TableCell>
                      {obj.chemotherapy_modalities?.length > 0 &&
                        obj.chemotherapy_modalities.map((item, i) => (
                          <p
                            style={{
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                              paddingBottom: "10px",
                            }}
                            key={i}
                          >
                            {" "}
                            {item.detail}
                          </p>
                        ))}
                    </TableCell>
                    <TableCell>{obj.line_of_treatment}</TableCell>

                    <TableCell>
                      {renderProtocols(obj.chemotherapy_protocols)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <h3>Surgery :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Surgery Date</TableCell>
                    <TableCell>Modality</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.surgeries?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {" "}
                        {row.surgery_date &&
                          dayjs(row.surgery_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>{row.modality}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Radiotherapy Schedules :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Intent</TableCell>
                    <TableCell>Detail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj?.radiotherapy_schedules?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {" "}
                        {row.start_date &&
                          dayjs(row.start_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {row.end_date &&
                          dayjs(row.end_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>{row.intent}</TableCell>
                      <TableCell>
                        {row.detail?.length > 0 &&
                          row.detail.map((item, i) => item.value).join(" , ")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Clinical Response Rate:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Target lesion</TableCell>
                    <TableCell>Non target lesion</TableCell>
                    <TableCell>New Lasion</TableCell>
                    <TableCell>Response Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {obj.clinical_response_rate_date &&
                        dayjs(obj.clinical_response_rate_date).format(
                          "DD-MM-YYYY"
                        )}
                    </TableCell>
                    <TableCell>
                      {obj.clinical_response_rate_target_lasion}
                    </TableCell>
                    <TableCell>
                      {obj.clinical_response_rate_non_target_lasion}
                    </TableCell>
                    <TableCell>
                      {obj.clinical_response_rate_non_target_lasion}
                    </TableCell>
                    <TableCell>{obj.clinical_response_rate_result}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Pathological Response Rate:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Target lesion</TableCell>
                    <TableCell>Non target lesion</TableCell>
                    <TableCell>New Lasion</TableCell>
                    <TableCell>Response Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {obj.pathological_response_rate_date &&
                        dayjs(obj.pathological_response_rate_date).format(
                          "DD-MM-YYYY"
                        )}
                    </TableCell>
                    <TableCell>
                      {obj.pathological_response_rate_target_lasion}
                    </TableCell>
                    <TableCell>
                      {obj.pathological_response_rate_non_target_lasion}
                    </TableCell>
                    <TableCell>
                      {obj.pathological_response_rate_non_target_lasion}
                    </TableCell>
                    <TableCell>
                      {obj.pathological_response_rate_result}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Radiological Response Rate:</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Target lesion</TableCell>
                    <TableCell>Non target lesion</TableCell>
                    <TableCell>New Lasion</TableCell>
                    <TableCell>Response Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {obj.radiological_response_rate_date &&
                        dayjs(obj.radiological_response_rate_date).format(
                          "DD-MM-YYYY"
                        )}
                    </TableCell>
                    <TableCell>
                      {obj.radiological_response_rate_target_lasion}
                    </TableCell>
                    <TableCell>
                      {obj.radiological_response_rate_non_target_lasion}
                    </TableCell>
                    <TableCell>
                      {obj.radiological_response_rate_non_target_lasion}
                    </TableCell>
                    <TableCell>
                      {obj.radiological_response_rate_result}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <h3>Disease Progression Status :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Disease Progression Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {obj.disease_progression_status_date &&
                        dayjs(obj.disease_progression_status_date).format(
                          "DD-MM-YYYY"
                        )}
                    </TableCell>
                    <TableCell>{obj.disease_progression_status}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Survival Status :</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Survival Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {obj.survival_status_date &&
                        dayjs(obj.survival_status_date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>{obj.survival_status}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PreviewPage;
