import React, { useState } from "react";
// @ts-ignore
import { useFormik } from "formik";
import {
  Alert,
  Button,
  Collapse,
  IconButton,
  Grid,
  Box,
  Container,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// @ts-ignore
import * as Yup from "yup";
import adminAPi from "../../../utils/adminAPi";
import { FeTextField } from "../../FormElements";

export default function ResetPassword() {
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  const validationSchema = Yup.object({
    current_password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    new_password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("new_password")], "Password does not match"),
  });

  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);

      adminAPi
        .post("admin/auth/reset-password", values)
        .then((response) => {
          setLoading(false);
          if (response.data.message) {
            setSuccessMessage(response.data.message);
            setOpen(true);
            setErrorOpen(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.data.message) {
            setErrorMessage(error.response.data.message);
            setErrorOpen(true);
            setOpen(false);
          }
          if (error.response.data.data !== undefined) {
            setErrorOpen(true);
            setOpen(false);
          }

          if (error.response.data.errors !== undefined) {
            setBackendErrorMessage(error.response.data.errors);
          }
        });
    },
  });

  return (
    <// @ts-ignore
    Box
    >
      <// @ts-ignore
      Container
      >
        <// @ts-ignore
        Grid
          container
          justifyContent="center"
        >
          <// @ts-ignore
          Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <// @ts-ignore
            Box
              mt={4}
              p={3}
              sx={{ border: "1px solid #ccc", borderRadius: 4 }}
            >
              <form onSubmit={formik.handleSubmit}>
                <// @ts-ignore
                Grid
                  container
                  spacing={3}
                >
                  <// @ts-ignore
                  Grid
                    item
                    xs={12}
                  >
                    <// @ts-ignore
                    FeTextField
                      fullWidth
                      label="Old Password"
                      type="text"
                      name="current_password"
                      {...formik.getFieldProps("current_password")}
                    />
                    <div className="error-message">
                      {formik.touched.current_password &&
                        formik.errors.current_password}
                    </div>
                    <p className="error-message">
                      {
                        // @ts-ignore
                        backendErrorMessage.current_password
                      }
                    </p>
                  </Grid>
                  <// @ts-ignore
                  Grid
                    item
                    xs={12}
                  >
                    <// @ts-ignore
                    FeTextField
                      fullWidth
                      label="New Password"
                      type="text"
                      name="new_password"
                      {...formik.getFieldProps("new_password")}
                    />
                    <div className="error-message">
                      {formik.touched.new_password &&
                        formik.errors.new_password}
                    </div>
                    <p className="error-message">
                      {
                        // @ts-ignore
                        backendErrorMessage.new_password
                      }
                    </p>
                  </Grid>
                  <// @ts-ignore
                  Grid
                    item
                    xs={12}
                  >
                    <// @ts-ignore
                    FeTextField
                      fullWidth
                      label="Confirm Password"
                      type="text"
                      name="confirm_password"
                      {...formik.getFieldProps("confirm_password")}
                    />
                    <div className="error-message">
                      {formik.touched.confirm_password &&
                        formik.errors.confirm_password}
                    </div>
                    <p className="error-message">
                      {
                        // @ts-ignore
                        backendErrorMessage.confirm_password
                      }
                    </p>
                  </Grid>
                  <// @ts-ignore
                  Grid
                    item
                    xs={12}
                  >
                    <// @ts-ignore
                    Collapse
                      in={open}
                    >
                      <// @ts-ignore
                      Alert
                        action={
                          <// @ts-ignore
                          IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            <// @ts-ignore
                            CloseIcon
                              fontSize="inherit"
                            />
                          </IconButton>
                        }
                      >
                        {successMessage}
                      </Alert>
                    </Collapse>
                    <// @ts-ignore
                    Collapse
                      in={errorOpen}
                    >
                      <// @ts-ignore
                      Alert
                        severity="error"
                        action={
                          <// @ts-ignore
                          IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setErrorOpen(false);
                            }}
                          >
                            <// @ts-ignore
                            CloseIcon
                              fontSize="inherit"
                            />
                          </IconButton>
                        }
                      >
                        {errorMessage}
                      </Alert>
                    </Collapse>
                  </Grid>
                  <// @ts-ignore
                  Grid
                    item
                    xs={12}
                    textAlign="right"
                  >
                    <// @ts-ignore
                    Button
                      disabled={loading}
                      type="submit"
                      variant="contained"
                      color="success"
                    >
                      {loading && <i className="fa fa-refresh fa-spin"></i>}
                      {loading ? "Reseting Password" : "Reset Password"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
